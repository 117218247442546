import axios from "axios";
import React, { useState } from "react";
import configData from "./../../config.json";
import SearchSuburbAutocomplete from "./SearchSuburbAutocomplete";
import RateTable from "./RateTable";
import VehicleDetail from "./VehicleDetail";

export default function Quoteform() {
  const token = localStorage.getItem("accessToken");

  const [departureSuburb, setDepartureSuburb] = useState({});
  const [destinationSuburb, setDestinationSuburb] = useState({});

  const [message, setMessage] = useState("");
  const [data, setData] = useState("");
  const [showHideRate, setShowHideRate] = useState(false);
  const [AddGoodCount, setAddGoodCount] = useState(0);

  const [formData, setFormData] = useState([
    {
      departureSuburb: "",
      destinationSuburb: "",
      vehicleDetail: [],
    },
  ]);

  //  ************************ Search Suburbs ***************
  const handleDepartureSuburbSelect = (value) => {
    setDepartureSuburb(value);
    console.log("Departure = ");

    const FormData = [...formData];
    FormData[0]["departureSuburb"] = value.value;
    setFormData(FormData);
    console.log(FormData);
  };
  const handleDestinationSuburbSelect = (value) => {
    setDestinationSuburb(value);
    console.log(value);
    const FormData = [...formData];
    FormData[0]["destinationSuburb"] = value.value;
    setFormData(FormData);
    console.log(FormData);
  };

  //  ************************  Submit Form  ***************
  let handleSubmit = async () => {
    const FormData = formData[0];
    console.log(FormData.vehicleDetail.length);

    if (
      FormData.departureSuburb != "" &&
      FormData.destinationSuburb != "" &&
      FormData.vehicleDetail.length != 0
    ) {
      if (
        FormData.vehicleDetail[0].makeId != "" &&
        FormData.vehicleDetail[0].modelId != "" &&
        FormData.vehicleDetail[0].yearId != ""
      ) {
        console.log("Form Valid");
        setMessage("");
        console.log(JSON.stringify(FormData));
        const res = await axios.post(
          configData.SERVER_URL + "get_rate",
          FormData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log(res);

        if (res.status === 200) {
          if (res.data.status === 1) {
            console.log(res.data);
            setData(res.data);
            setShowHideRate(true);
          } else {
            setMessage(res.data.msg);
            setShowHideRate(false);
          }
        } else {
          setMessage("Some error occured");
        }
      } else {
        console.log("Form not Valid");
        setMessage("All Field Required");
      }
    } else {
      console.log("Form not Valid");
      setMessage("All Field Required");
    }
  };

  function vehicleDetailStore(data) {
    console.log("Data from Parent");
    console.log(data);

    const FormData = [...formData];
    FormData[0]["vehicleDetail"] = data;
    setFormData(FormData);
    console.log("Form Data = ");
    console.log(FormData);

    if (showHideRate) {
      console.log("change form");
      handleSubmit();
    }
  }

  function addGoodCountUpdate(data) {
    setAddGoodCount(data);
    console.log("add Good Count from main component = " + data);
  }

  let sendRequest = async () => {
    const res = await fetch(configData.SERVER_URL + "testing", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(FormData),
    });

    const Res = await res.json();

    console.log(Res);
  };

  return (
    <div>
      <section className="quote_form primary-bg quote-padding">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1> New Quote </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="quote-form-area  quote-form">
                <div className="row">
                  {message ? (
                    <div className="col-12 text-center ">
                      <div className="message text-danger">
                        <h5 className="text-danger">{message}</h5>
                      </div>
                    </div>
                  ) : null}
                  <div className="col-md-6 col-12 ">
                    <div className="form-group" id="name-field">
                      <div className="form-input">
                        <label for="make">Departure:</label>
                        <SearchSuburbAutocomplete
                          inputProps={{
                            id: "departureSuburb",
                            value: departureSuburb.label || "",
                          }}
                          onSourceSelect={handleDepartureSuburbSelect}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 ">
                    <div className="form-group" id="name-field">
                      <div className="form-input">
                        <label for="make">Destination:</label>
                        <SearchSuburbAutocomplete
                          inputProps={{
                            id: "destinationSuburb",
                            value: destinationSuburb.label || "",
                          }}
                          onSourceSelect={handleDestinationSuburbSelect}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <VehicleDetail
                  passData={vehicleDetailStore}
                  addGoodCountUpdate={addGoodCountUpdate}
                />
                <div className="row mt-3">
                  <div className="col-12">
                    <div className="form-group  text-center">
                      <button type="submit" onClick={handleSubmit}>
                        Show Rates
                      </button>
                    </div>
                  </div>
                </div>

                {showHideRate && (
                  <RateTable data={data} addGoodCount={AddGoodCount} />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
