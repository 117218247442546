import React, { useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import configData from "./../../config.json";

import "./loginFromStyle.css";

export default function Login() {
  // React States
  const [formData, setFormData] = useState([{ email: "", password: "" }]);
  const [errorMessages, setErrorMessages] = useState('');

  const handleServiceChange = (e) => {
    const { name, value } = e.target;
    const list = [...formData];
    list[0][name] = value;
    setFormData(list);
    console.log(list);
  };

  //  ************************  Submit Login Form  ***************

  async function submitLoginForm(e) {
    e.preventDefault();
    const FormData = formData[0];
    const response = await fetch(configData.SERVER_URL + "login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(FormData),
    });
    const res = await response.json();
    console.log(res.user)
    console.log(res.status)
    if (res.status === true) {
      localStorage.setItem("accessToken", res.token);
      localStorage.setItem("user",JSON.stringify(res.user));
      console.log(res)
      window.location.href = "/";
  
    } 
      setErrorMessages(res.message)

  }

  return (
    <div className="app">
      <div className="login-form">
        <div className="logo">
          <img
            src="https://emucarcarriers.com.au/public/images/home/EMU-logo.webp"
            alt="logo"
          />
        </div>
        <div className="title">Welcome to EMU Portal</div>
        <div className="form">
          <form>
            <div className="input-container">
              <label>Username </label>
              <input
                type="text"
                name="email"
                required
                onChange={(e) => handleServiceChange(e)}
              />
            </div>
            <div className="input-container">
              <label>Password </label>
              <input
                type="password"
                name="password"
                required
                onChange={(e) => handleServiceChange(e)}
              />
            </div>
            
              {errorMessages
              ?
              (
                <div className="error">
                {errorMessages}
                </div>
              ) : null
              }
            <div className="button-container">
              <button
                className="loginBtn"
                type="submit"
                onClick={submitLoginForm}
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
