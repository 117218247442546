import logo from "./logo.svg";
import "./App.css";
import { Outlet, Route, Routes } from "react-router-dom";
import Header from "./View/Components/Header";
import Footer from "./View/Components/Footer";
import QuoteForm from "./View/Components/QuoteForm";
import Booking from "./View/Components/Booking";
import ConfirmBooking from "./View/Components/ConfirmBooking";
import NotFound from "./View/Components/NotFound"; 
import VehicleDetail from "./View/Components/VehicleDetail";
import TestComponent from "./View/Components/TestComponent";
import Login from "./View/Components/Login";

function App() {

  const token = localStorage.getItem('accessToken');

  if(!token) {
    return <Login />
  }

  
  return (
    <>
      <Routes>
        <Route path="/" element={<LayoutsWithNavbar />}>
        <Route path="/" element={<QuoteForm />} />
        <Route path="/vehicle-detail" element={<VehicleDetail />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/confirm-booking" element={<ConfirmBooking />} />
        <Route path="/test-page" element={<TestComponent />} />
        <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;

function LayoutsWithNavbar() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}
