import React from "react";
import { useLocation } from "react-router-dom";

export default function ConfirmBooking() {
  const location = useLocation();
  const allData = location.state;


  
  console.log(allData)
  console.log(allData['allData']['refId'])
  console.log(allData['allData'].Data['rate']['allVehicleRateSum'])



  return (
    <div>
      <section className="content-body">
        <section className="quote_detail gray-bg quote-padding">
          <div className="container quote-deatils">
            <div className="row">
              <div className="col-12">
                <div className="quote_card">
                  <div className="row">
                    <h2>THANK YOU FOR YOUR BOOKING</h2>
                    <p>
                    Your Reference Number is
                      <span className="text-info fw-bold "> { allData['allData']['refId'] }</span> <br />
                      Your booking was successfully received by our system.
                      <br />
                    </p>
                  </div>

                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                    <h3>BILLING AMOUNT:</h3>
                    AUD ${ allData['allData'].Data['rate']['allVehicleRateSum'] }
                    <br />
                    <br />
                    <h6> What Happens Next?</h6>
                    <p>
                    At this stage, your booking is being processed by the team. 
                    <br></br>
                    <br></br>
 This involves confirming the details are correct and that we accurately captured all the necessary information.
 Once processed you will get an email with booking confirmation
 <br></br>
 Have a Question about your Booking?
  <br></br>
  <br></br>
 Call our Customer Service on 1300 524 520 with any questions you may have about your quote or any part of your booking.
                      
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}
