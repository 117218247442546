import React from 'react';
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <div>

            <div className="footer-area priBlueText">

                <div className="footer-bottom-area wow fadeIn">
                    <div className="container">
                        <div className="footer-top-area wow fadeIn">

                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="single-footer-widget footer-about">
                                    <h3>Contact Us</h3>
                                    <ul>
                                        <li><i className="fa fa-phone"></i> <a href="callto:1300 524 520">1300 524 520</a></li>
                                        <li><i className="fas fa-envelope"></i> <a href="mailto:info@emucarcarriers.com.au">info@emucarcarriers.com.au</a></li>
                                    </ul>
                                </div>
                                <div className="footer-social">
                                    <ul className="social-bookmark">
                                        <li><a className="facebook" href="https://www.facebook.com/Emu-Car-Carriers-104977398375317"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a className="instagram" href="https://www.instagram.com/emucarcarriers/"><i className="fab fa-instagram"></i></a></li>
                                        <li><a className="twitter" href="https://twitter.com/emucarcarriers"><i className="fab fa-twitter"></i></a></li>
                                        <li><a className="quora" href="https://www.quora.com/profile/Emu-Car-Carriers"><i className="fab fa-quora"></i></a></li>
                                        <li><a className="pinterest" href="https://www.pinterest.com/emucarcarriers/"><i className="fab fa-pinterest-p"></i></a></li>
                                        <li><a className="linkedin" href="https://www.linkedin.com/company/emu-car-carriers"><i className="fab fa-linkedin-in"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4  col-sm-6 col-xs-12">
                                <div className="single-footer-widget twitter-widget">
                                    <h3>Quick Links</h3>
                                    <ul>
                                        <li><Link className="navbar-brand " to="/">Home</Link></li>
                                        <li><a href="https://emucarcarriers.com.au/about">ABOUT</a></li>
                                        <li><a href="https://emucarcarriers.com.au/service">SERVICES</a></li>
                                        <li><a href="https://emucarcarriers.com.au/blog">BLOG</a></li>
                                        <li><a href="https://emucarcarriers.com.au/contact">CONTACT</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4  col-sm-6 col-xs-12">
                                <div className="single-footer-widget list-widget">
                                    <h3>Customer Service</h3>
                                    <ul>
                                        <li><a href="https://emucarcarriers.com.au/faq"> <i className="far fa-thumbs-up"></i>&nbsp;&nbsp; FAQS</a></li>
                                        <li><a href="https://emucarcarriers.com.au/privacy"> <i className="far fa-thumbs-up"></i>&nbsp;&nbsp; Privacy Policy</a></li>
                                        <li><a href="https://emucarcarriers.com.au/terms-and-Conditions"> <i className="far fa-thumbs-up"></i>&nbsp;&nbsp; Terms & Conditions</a></li>
                                        <li><a href="https://emucarcarriers.com.au/contact"> <i className="far fa-thumbs-up"></i>&nbsp;&nbsp; Contact Us</a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="footer-border"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                <div className="footer-copyright wow fadeIn">

                                    <p>Copyright &copy;<script>
                                        document.write(new Date().getFullYear());
                                    </script> All rights reserved | <a href="https://emucarcarriers.com.au/" target="_blank">emucartransport</a></p>

                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                <div className="footer-social-bookmark text-right wow fadeIn">
                                    <ul className="social-bookmark">
                                        <li><a href="https://www.facebook.com/Emu-Car-Carriers-104977398375317" target="_blank"
                                            rel="noreferrer" ><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="https://www.instagram.com/emucarcarriers/" target="_blank"
                                            rel="noreferrer" ><i className="fab fa-instagram"></i></a></li>
                                        <li><a href="https://twitter.com/emucarcarriers" target="_blank"
                                            rel="noreferrer" ><i className="fab fa-twitter"></i></a></li>
                                        <li><a href="https://www.quora.com/profile/Emu-Car-Carriers" target="_blank"
                                            rel="noreferrer" ><i className="fab fa-quora"></i></a></li>
                                        <li><a href="https://www.pinterest.com/emucarcarriers/" target="_blank"
                                            rel="noreferrer" ><i className="fab fa-pinterest-p"></i></a></li>
                                        <li><a href="https://www.linkedin.com/company/emu-car-carriers" target="_blank"
                                            rel="noreferrer" ><i className="fab fa-linkedin-in"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </div>
    )
}
