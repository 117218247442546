import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

export default function NotFound() {
  return (
    <>
      <section className="quote_detail gray-bg quote-padding">
        <div className="container quote-deatils">
          <div className="row">
            <div className="col-12">
              <div className="error-section pt-100 pb-100">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/error-404-page-not-found.webp"
                  }
                  alt="error"
                />
                <div className="theme-btn text-center">
                  <Link to="/">
                    <Button variant="primary">Back To Home</Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
