import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import configData from "./../../config.json";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { useNavigate } from "react-router-dom";

export default function RateTable({ data, addGoodCount }) {
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [refId, setRefId] = useState(null);
  const [formData, setFormData] = useState([]);
  const [serviceLevel, setServiceLevel] = useState(null);
  const [error, setError] = useState(null);
  const TotalGoodsCollectionRate =
    parseInt(addGoodCount) * parseInt(data.rate["goodsCollectionRate"]);
  console.log(TotalGoodsCollectionRate);

  function refreshPage() {
    window.location.reload(false);
  }

  //  ************************  Save Quote ***************

  let saveQuote = async (e) => {
    // e.preventDefault();
    const formData = data.quoteData;

    console.log(JSON.stringify(formData));
    const res = await axios.post(
      configData.SERVER_URL + "save_quote",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log(res.status);

    if (res.status === 200) {
      if (res.data.status === 1) {
        console.log(res.data);
        setRefId(res.data.refId);
        console.log("Ref Id from save quote =" + res.data.refId);
        return res.data.refId;
      } else {
        console.log(res.data.msg);
      }
    } else {
      console.log("Some error occured");
    }
  };

  const serviceLevelChange = (e) => {
    console.log(e.target.value);

    setServiceLevel(e.target.value);
  };

  let gotoBooking = async () => {
    // console.log(serviceLevel);
    if (serviceLevel === null) {
      setError("Please select a service level");
      return; 
    }

    if (refId === null) {
      console.log("Ref id  is null");
      let quoteData = await saveQuote();
      const Data = {
        Data: data,
        serviceLevel: serviceLevel,
        refId: quoteData,
        addGoodCount: addGoodCount,
      };
      console.log(Data);
      console.log(JSON.stringify(Data));
      navigate("/booking", { state: Data });
    } else {
      const Data = {
        Data: data,
        serviceLevel: serviceLevel,
        refId: refId,
        addGoodCount: addGoodCount,
      };
      console.log(Data);
      console.log(JSON.stringify(Data));
      navigate("/booking", { state: Data });
    }
  };

  return (
    <div>
      <hr></hr>
      <hr></hr>
      <div className="row mt-3">
        {/* <div className="col-md-3 col-12">
          {addGoodCount}
          <br></br>
          {TotalGoodsCollectionRate}
        </div> */}
        <div className="col-12">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Check</th>
                <th>Pickup From</th>
                <th>Deliver To</th>
                <th>Service Level</th>
                <th>Transit Time</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {data.rate[0].destination_depot_status === "On" &&
              data.rate[0].departure_depot_status === "On" ? (
                <tr>
                  <td>
                    <Form.Check
                      type="radio"
                      name="service_level"
                      value="Depot-Depot"
                      onChange={serviceLevelChange}
                      defaultChecked
                    />
                  </td>
                  <td>{data.rate[0].departure_depot.full_name}</td>
                  <td>{data.rate[0].destination_depot.full_name}</td>
                  <td>Depot To Depot</td>
                  <td>
                    {data.rate[0].transitFrom}-{data.rate[0].transitTo}
                  </td>
                  <td>
                    $
                    {parseInt(data.rate["allVehicleRateSum"]) +
                      TotalGoodsCollectionRate}
                  </td>
                </tr>
              ) : (
                ""
              )}

              {data.rate[0].departure_depot_status === "On" ? (
                <tr>
                  <td>
                    <Form.Check
                      type="radio"
                      name="service_level"
                      value="Depot-Door"
                      onChange={serviceLevelChange}
                    />
                  </td>
                  <td>{data.rate[0].departure_depot.full_name}</td>
                  <td>{data.quoteData.destinationSuburb}</td>
                  <td>Depot To Door</td>
                  <td>
                    {parseInt(data.rate[0].transitFrom) + 1}-
                    {parseInt(data.rate[0].transitTo) + 1}
                  </td>
                  <td>
                    $
                    {parseInt(data.rate["allVehicleRateSum"]) +
                      parseInt(data.rate["allDoorDeliveryRate"]) +
                      TotalGoodsCollectionRate}
                  </td>
                </tr>
              ) : (
                ""
              )}

              {data.rate[0].destination_depot_status === "On" ? (
                <tr>
                  <td>
                    <Form.Check
                      type="radio"
                      name="service_level"
                      value="Door-Depot"
                      onChange={serviceLevelChange}
                    />
                  </td>
                  <td>{data.quoteData.departureSuburb}</td>
                  <td>{data.rate[0].destination_depot.full_name}</td>
                  <td>Door To Depot</td>
                  <td>
                    {parseInt(data.rate[0].transitFrom) + 1}-
                    {parseInt(data.rate[0].transitTo) + 1}
                  </td>
                  <td>
                    $
                    {parseInt(data.rate["allVehicleRateSum"]) +
                      parseInt(data.rate["allDoorCollectionRate"]) +
                      TotalGoodsCollectionRate}
                  </td>
                </tr>
              ) : (
                ""
              )}
              <tr>
                <td>
                  <Form.Check
                    type="radio"
                    name="service_level"
                    value="Door-Door"
                    onChange={serviceLevelChange}
                  />
                </td>
                <td>{data.quoteData.departureSuburb}</td>
                <td>{data.quoteData.destinationSuburb}</td>
                <td>Door To Door</td>
                <td>
                  {parseInt(data.rate[0].transitFrom) + 2}-
                  {parseInt(data.rate[0].transitTo) + 2}
                </td>
                <td>
                  $
                  {parseInt(data.rate["allVehicleRateSum"]) +
                    parseInt(data.rate["allDoorCollectionRate"]) +
                    parseInt(data.rate["allDoorDeliveryRate"]) +
                    TotalGoodsCollectionRate}
                </td>
              </tr>
            </tbody>
          </Table>
          {error ? <div className="text-danger">{error}</div> : null}
        </div>
      </div>
      <hr></hr>
      <div className="row ">
        <div className="col-3">
          <div className="form-group">
            <Button variant="secondary" onClick={refreshPage}>
              New Quote
            </Button>
          </div>
        </div>
        {refId ? null : (
          <div className="col-3">
            <div className="form-group">
              <Button variant="info" onClick={saveQuote}>
                Save Quote
              </Button>
            </div>
          </div>
        )}

        <div className="col-3">
          <div className="form-group">
            <Button variant="outline-primary" onClick={gotoBooking}>
              Booking
            </Button>
          </div>
        </div>
        <div className="col-3">
          {refId ? (
            <h3>
              <Badge bg="success">Ref Id: {refId}</Badge>
            </h3>
          ) : null}
        </div>
      </div>
    </div>
  );
}
