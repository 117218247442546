import React, { useState, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import configData from "./../../config.json";
import { useNavigate } from "react-router-dom";

import moment from "moment";

export default function Booking() {
  const token = localStorage.getItem("accessToken");
  const [startDate, setStartDate] = useState(null);
  const navigate = useNavigate();
  const [formData, setFormData] = useState(null);
  const location = useLocation();
  const depName = useRef(null);
  const depPhone = useRef(null);
  const desName = useRef(null);
  const desPhone = useRef(null);
  const FormData = location.state;
  let user = JSON.parse(localStorage.getItem("user") || "[]");
  console.log(user['name']);
  const name = user['name'];
  const phone = user['phone'];

  const holidays = [
    new Date("2022-12-26"),
    new Date("2022-12-27"),
    new Date("2022-12-28"),
    new Date("2022-12-29"),
    new Date("2022-12-30"),
    new Date("2023-01-02"),
    new Date("2023-01-03"),
  ];

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  console.log(location.state);

  const sameBookingDetailCheckBox = (e, type) => {
    const list = FormData;
    if (e.target.checked) {
      if (type === "des") {
        desName.current.value = name;
        desPhone.current.value = phone;
        list.Data[desName.current.name] = name;
        list.Data[desPhone.current.name] = phone;
      } else {
        depName.current.value = name;
        depPhone.current.value = phone;
        list.Data[depName.current.name] = name;
        list.Data[depPhone.current.name] = phone;
      }
    } else {
      if (type === "des") {
        desName.current.value = null;
        desPhone.current.value = null;
        list.Data[desName.current.name] = null;
        list.Data[desPhone.current.name] = null;
      } else {
        depName.current.value = null;
        depPhone.current.value = null;
        list.Data[depName.current.name] = null;
        list.Data[depPhone.current.name] = null;

      }
    }
    console.log(list)
    setFormData(list);
  };

  console.log("Data")

  // setFormData(location.state);

  console.log(FormData)


  const handleCarDetailChange = (e, index) => {
    const { name, value } = e.target;
    console.log(name);
    console.log(value);
    const list = FormData;
    list.Data.quoteData.vehicleDetail[index][name] = value;
    console.log(list)
    setFormData(list);
  };

  const handleBookingDetailChange = (e, index) => {
    const { name, value } = e.target;
    console.log(name);
    console.log(value);
    const list = FormData;
    list.Data[name] = value;
    console.log(list)
    setFormData(list);
  };

  const handleAvailableDateChange = (e) => {
    const date = moment(e).format("YYYY-MM-DD");
    console.log(date);
    setStartDate(e)
    const list = FormData;
    list.Data["availableDate"] = date;
    console.log(list)
    setFormData(list);
  };


  //  ************************  Save Booking  ***************

  let saveBooking = async (e) => {
    // const saveBooking = () => {

    console.log(JSON.stringify(formData));
    const res = await axios.post(
      configData.SERVER_URL + "save_booking",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    console.log(res.status);

    if (res.status === 200) {
      if (res.data.status === 1) {
        console.log(res.data);
        navigate('/confirm-booking', { state: res.data });
      } else {
        console.log(res.data.msg);
      }
    } else {
      console.log("Some error occured");
    }



  };


  return (
    <div>
      <section className="quote_detail gray-bg quote-padding">
        <div className="container quote-deatils">
          <div className="row">
            <div className="col-12">
              <div className="quote_card">
                <div className="row">
                  <h3 className="margin">Booking Details</h3>


                </div>
                {location.state.Data.quoteData.vehicleDetail.map(
                  (vehicleDetail, index) => (
                    <>
                      <div className="row ">
                        <div className="col-12 ">
                          Vehicle:{index + 1}
                          <br></br>
                          {vehicleDetail.carMakeDetail.m_name} ||{" "}
                          {vehicleDetail.carModelDetail.model_name} ||{" "}
                          {vehicleDetail.carYearDetail.year}
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label for="vin">Rego or VIN: *</label>
                            <input
                              type="text"
                              className="form-control"
                              id="vin"
                              placeholder="Rego or VIN"
                              name="carVin"
                              onChange={(e) => handleCarDetailChange(e, index)}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label for="color">Vehicle Colour: *</label>
                            <input
                              type="text"
                              className="form-control"
                              id="color"
                              placeholder="Colour"
                              onChange={(e) => handleCarDetailChange(e, index)}
                              name="carColor"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <hr></hr>
                    </>
                  )
                )}

                <div class="row">
                  <div className="col-2">
                    <label for="date">Available From: *</label>
                  </div>
                  <div className="col-2 ">
                    <DatePicker
                      selected={startDate}
                      minDate={new Date()}
                      filterDate={isWeekday}
                      excludeDates={holidays}
                      // format='yyyy-MM-dd'
                      dateFormat="dd-MM-yyyy"
                      name="availableDate"
                      onChange={(date) => handleAvailableDateChange(date)}
                    />
                  </div>
                </div>
                <hr></hr>
                <hr></hr>
                <div className="row">
                  <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                    <h4 className="margin">Origin Details</h4>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="samePersonalDetailDepartureCheck"
                        onChange={(e) => sameBookingDetailCheckBox(e, "dep")}
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault"
                      >
                        Origin Details same as Booking Details
                      </label>
                    </div>
                    <div className="form-group">
                      <label for="vin">Name: *</label>
                      <input
                        type="text"
                        className="form-control"
                        name="departureCustomerName"
                        placeholder="Name of Contact Person at Pickup"
                        ref={depName}
                        onChange={(e) => handleBookingDetailChange(e)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label for="vin">Phone: *</label>
                      <input
                        type="number"
                        className="form-control"
                        name="departureCustomerPhone"
                        placeholder="Contact Phone"
                        ref={depPhone}
                        onChange={(e) => handleBookingDetailChange(e)}
                        required
                      />
                    </div>

                    { location.state.serviceLevel === "Door-Depot" || location.state.serviceLevel === "Door-Door" ?
                        <div className="form-group">
                          <label for="vin">Street Address: *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="departureStreetAddress"
                            placeholder="Street Address"
                            onChange={(e) => handleBookingDetailChange(e)}
                            required
                          />
                        </div>
                     :""}
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                    <h4 className="margin">Destination Details</h4>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="samePersonalDetailDestinationCheck"
                        onChange={(e) => sameBookingDetailCheckBox(e, "des")}
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault"
                      >
                        Origin Details same as Booking Details
                      </label>
                    </div>
                    <div className="form-group">
                      <label for="vin">Name: *</label>
                      <input
                        type="text"
                        ref={desName}
                        className="form-control"
                        name="destinationCustomerName"
                        placeholder="Name of Contact Person at Delivery"
                        onChange={(e) => handleBookingDetailChange(e)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label for="vin">Phone: *</label>
                      <input
                        type="number"
                        ref={desPhone}
                        className="form-control"
                        name="destinationCustomerPhone"
                        placeholder="Delivery Phone"
                        onChange={(e) => handleBookingDetailChange(e)}
                        required
                      />
                    </div>

                    {location.state.serviceLevel === "Depot-Door" || location.state.serviceLevel === "Door-Door" ?
                      <div className="form-group">
                        <label for="vin">Street Address: *</label>
                        <input
                          type="text"
                          className="form-control"
                          name="destinationStreetAddress"
                          placeholder="Street Address"
                          onChange={(e) => handleBookingDetailChange(e)}
                          required
                        />
                      </div>
                      :""}
                  </div>
                </div>

                <button className="btn" onClick={saveBooking}>
                  Cofirm Booking
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
