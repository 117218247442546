import React, { useState } from "react";
import { Link} from "react-router-dom";
import axios from "axios";
import configData from "./../../config.json";


export default function Header() {
  const token = localStorage.getItem("accessToken");

  let user = JSON.parse(localStorage.getItem("user") || "[]");
  console.log(user['name']);

  const handleLogout = async () => {
  //  await axios.get(
  //     configData.SERVER_URL + "logout",{
  //       headers: {
  //         Authorization: `Bearer ${token}`
  //       }
  //     });
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    window.location.href = "/";
  };


  return (
    <div>
      <a href="#home" className="scrolltotop">
        <i className="fas fa-long-arrow-alt-up"></i>
      </a>
      <div className="topbar" id="topbar">
        <div className="container">
          <div className="row py-2 px-lg-5">
            <div className="col-lg-6 text-center text-lg-left mb-2 mb-lg-0">
              <div className=" contact-links d-inline-flex align-items-center ">
                <a href="callto:1300 524 520">
                  <i className="fa fa-phone-alt mr-2"></i> 1300 524 520
                </a>
                <small className="px-3">|</small>

                <a href="mailto:info@emucarcarriers.com.au">
                  <i className="fa fa-envelope mr-2"></i>
                  info@emucarcarriers.com.au
                </a>
              </div>
            </div>
            <div className="col-lg-6 text-center text-lg-right">
              <div className="social-links d-inline-flex align-items-center">
                <b>
                  <a href="https://www.facebook.com/Emu-Car-Carriers-104977398375317">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="https://www.instagram.com/emucarcarriers/">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="https://twitter.com/emucarcarriers">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="https://www.quora.com/profile/Emu-Car-Carriers">
                    <i className="fab fa-quora"></i>
                  </a>
                  <a href="https://www.pinterest.com/emucarcarriers/">
                    <i className="fab fa-pinterest-p"></i>
                  </a>
                  <a href="https://www.linkedin.com/company/emu-car-carriers">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-top-area">
        <div id="mainmenu-area-sticky-wrapper" className="sticky-wrapper">
          <div className="mainmenu-area" id="mainmenu-area">
            <div className="mainmenu-area-bg"></div>
            <nav className="navbar navbar-expand-lg">
              <div className="container">
                <div className="navbar-header text-center">
                    <Link className="navbar-brand " to="/">
                    <img
                      src="https://emucarcarriers.com.au/public/images/home/EMU-logo.webp"
                      alt="logo"
                    />
                 
                  </Link>
                  <br></br>
                  <h5 className="text-light" >
                {user['name']}
                  </h5>
                  

                </div>
                <button
                  type="button"
                  className="navbar-toggler"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarCollapse"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <Link className="nav-link" to="/">
                        Home
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/">
                        Get Quote
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        aria-current="page"
                        onClick={handleLogout}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
