import React, { useState, useEffect } from "react";
import Select from "react-select";
import configData from "./../../config.json";

export default function VehicleDetail(props) {
  const [makeList, setMakeList] = useState([{ m_name: "", id: "" }]);
  const [modelList, setModelList] = useState([{ model_name: "", id: "" }]);
  const [yearList, setYearList] = useState([{ year: "", id: "" }]);
  const [modificationTypeList, setModificationTypeList] = useState([
    { modification_type: "", id: "" },
  ]);
  
  //  ************************ Input Fields ***************
  const [makeId, setMakeId] = useState("");
  const [modificationType, setModificationType] = useState();
  const [modificationStatus, setModificationStatus] = useState();
  const [serviceList, setServiceList] = useState([
    {
      makeId: "",
      modelId: "",
      yearId: "",
      drivableStatus: "Drivable",
      modificationStatus: "Non Modified",
      modificationTypeId: null,
      modificationDetail: null,
      carGroundClearance: null,
      carLength: null,
      carWidth: null,
      carHeight: null,
      goodsStatus: "Empty",
      goodsRate: null,
    },
  ]);
  const [addGoodCount, setAddGoodCount] = useState(0);

  //  ************************ Car Manufacturer ***************
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        configData.SERVER_URL + `car_manufacturer_list`
      );
      const newData = await response.json();
      setMakeList(newData);
      // console.log(newData);
    };
    fetchData();
  }, []);
  const makeOption = makeList.map((make) => ({
    label: make.m_name,
    value: make.id,
  }));

  const makeChange = (e, index) => {
    setMakeId(e.value);
    console.log("Make Id=" + e.value);
    const list = [...serviceList];
    list[index]["makeId"] = e.value;
    console.log(list);
    setServiceList(list);
    props.passData(list);
  };

  //  ************************ Car Model ***************

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        configData.SERVER_URL + `car_model_list/${makeId}`
      );
      const newData = await response.json();
      setModelList(newData);
      // console.log(newData);
    };
    fetchData();
  }, [makeId]);

  const modelOption = modelList.map((model) => ({
    label: model.model_name,
    value: model.id,
  }));

  const modelChange = (e, index) => {
    console.log("Model Id=" + e.value);

    const list = [...serviceList];
    list[index]["modelId"] = e.value;
    console.log(list);
    setServiceList(list);
    props.passData(list);
  };

  //  ************************ Car Year ***************

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(configData.SERVER_URL + `car_year_list`);
      const newData = await response.json();
      setYearList(newData);
      // console.log(newData);
    };
    fetchData();
  }, []);

  const yearOption = yearList.map((year) => ({
    label: year.year,
    value: year.id,
  }));

  const yearChange = (e, index) => {
    console.log("Year Id=" + e.value);

    const list = [...serviceList];
    list[index]["yearId"] = e.value;
    console.log(list);
    setServiceList(list);
    props.passData(list);
  };

  //  ************************  Modification Type  ***************

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        configData.SERVER_URL + `car_modification_type_list`
      );
      const newData = await response.json();
      setModificationTypeList(newData);
      // console.log(newData);
    };
    fetchData();
  }, []);

  const modificationTypeOption = modificationTypeList.map((modification) => ({
    label: modification.modification_type,
    value: modification.id,
  }));

  //  ************************  Modification Type ***************
  const handleModificationType = (e, index) => {
    console.log("Modification Type = " + e.label);
    setModificationType(e.label);
    const list = [...serviceList];
    list[index]["modificationTypeId"] = e.value;
    console.log(list);
    setServiceList(list);
    props.passData(list);
  };

  const addGoodsChange = (event, index) => {
    const list = [...serviceList];
    if (!event.target.checked) {
      list[index]["goodsStatus"] = "Empty";
      if (addGoodCount === 0) {
        setAddGoodCount(0);
        props.addGoodCountUpdate(0);
      } else {
        setAddGoodCount(addGoodCount - 1);
        props.addGoodCountUpdate(addGoodCount - 1);
      }
    } else {
      list[index]["goodsStatus"] = "Goods";

      setAddGoodCount(addGoodCount + 1);

      props.addGoodCountUpdate(addGoodCount + 1);
    }

    console.log("Goods Count " + addGoodCount);

    // console.log("Goods Count ="+addGoodCount);
    // console.log(list);
    setServiceList(list);
    props.passData(list);
  };

  const drivableStatusChange = (e, index) => {
    const list = [...serviceList];
    if (!e.target.checked) {
      list[index]["drivableStatus"] = "Non Drivable";
      console.log("not checked");
    } else {
      list[index]["drivableStatus"] = "Drivable";
      console.log("Checked");
    }

    console.log(list);
    setServiceList(list);
    props.passData(list);
  };

  //  ************************  Modification Check Box ***************
  const handleModificationCheckBox = (e, index) => {
  

    const list = [...serviceList];

    if (!e.target.checked) {
      setModificationStatus("Non Modified");
      list[index]["modificationStatus"] = "Non Modified";
    } else {
      setModificationStatus("Modified");
      list[index]["modificationStatus"] = "Modified";
    }
    console.log(list);
    setServiceList(list);
    props.passData(list);
  };

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];
    list[index][name] = value;
    console.log(list);
    setServiceList(list);
    props.passData(list);
  };

  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
    props.passData(list);
  };

  const handleServiceAdd = () => {
    setServiceList([
      ...serviceList,
      {
        makeId: "",
        modelId: "",
        yearId: "",
        drivableStatus: "Drivable",
        modificationStatus: "Non Modified",
        modificationTypeId: null,
        modificationDetail: null,
        carGroundClearance: null,
        carLength: null,
        carWidth: null,
        carHeight: null,
        goodsStatus: "Empty",
        goodsRate: null,
      },
    ]);
  };

  // const passData = () => {
  //   props.passData(serviceList)
  // }

  return (
    <>
    <hr></hr>
       
      <div className="row">
        <div className="col-12">
          <div className="form-group  ">
            <button type="submit" onClick={handleServiceAdd}>
              <i className="fa-solid fa-plus"></i>
              ADD Vehicle-{serviceList.length}
            </button>
          </div>
        </div>
      </div>
     
      <br></br>
   
      {serviceList.map((singleService, index) => (
        <div>
          <div className="row">
            <div className="col-12">Vehicle No. {index + 1}</div>
            <div className="col-md-4 col-12">
              <div className="form-group" id="name-field">
                <div className="form-input">
                  <label for="make">Make:</label>
                  <Select
                    options={makeOption}
                    onChange={(e) => makeChange(e, index)}
                    placeholder="Select Make"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="form-group" id="email-field">
                <div className="form-input">
                  <label for="model">Model:</label>
                  <Select
                    options={modelOption}
                    onChange={(e) => modelChange(e, index)}
                    placeholder="Select Car Model"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="form-group" id="email-field">
                <div className="form-input">
                  <label for="year">Year:</label>
                  <Select
                    options={yearOption}
                    name="yearId"
                    onChange={(e) => yearChange(e, index)}
                    placeholder="Select Car Year"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4 col-12">
              <div className="form-group">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="drivable"
                    value="Drivable"
                    name="drivable"
                    onChange={(e) => drivableStatusChange(e, index)}
                    defaultChecked={true}
                  />
                  <label className="form-check-label" for="drivable">
                    Drivable
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="form-group">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="Modified"
                    id="modifications"
                    name="modification_status"
                    onChange={(e) => handleModificationCheckBox(e, index)}
                  />
                  <label className="form-check-label" for="modifications">
                    Modified
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="form-group">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="addGoods"
                    value="addGoods"
                    name="addGoods"
                    onChange={(e) => addGoodsChange(e, index)}
                  />
                  <label className="form-check-label" for="drivable">
                    ADD GOODS IN VEHICLE
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className={singleService.modificationStatus === "Modified" ? "" : "d-none"}
              id="modificationType-block"
            >
              <div className="form-group">
                <label for="modification_type">Modification Type:</label>
                <Select
                  options={modificationTypeOption}
                  name="modificationTypeId"
                  // onChange={handleModificationType}

                  onChange={(e) => handleModificationType(e, index)}
                  placeholder="Select Modification Type"
                />
              </div>
            </div>
            <div
              className={
                singleService.modificationTypeId === 1 && singleService.modificationStatus === "Modified"
                  ? ""
                  : "d-none"
              }
              id="modification-details"
            >
              <div className="form-group">
                <div className="form-input">
                  <label for="make">Details of modification:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="modificationDetail"
                    placeholder="Write here modification details...."
                    onChange={(e) => handleServiceChange(e, index)}
                  />
                </div>
              </div>
            </div>
            <div
              className={
                singleService.modificationTypeId === 5 && singleService.modificationStatus === "Modified"
                  ? ""
                  : "d-none"
              }
              id="ground-clearance"
            >
              <div className="form-group">
                <div className="form-input">
                  <label for="ground_clearance ">Ground Clearance(mm):</label>
                  <input
                    type="number"
                    className="form-control"
                    name="carGroundClearance"
                    placeholder="Write here Ground clearance in mm"
                    onChange={(e) => handleServiceChange(e, index)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={`row ${
              modificationType !== "Lowered" && singleService.modificationStatus === "Modified"
                ? ""
                : "d-none"
            }`}
            id="dimensions"
          >
            <div className="col-md-4 col-12">
              <div className="form-group">
                <div className="form-input">
                  <label for="length">Length(mm):</label>
                  <input
                    type="text"
                    name="carLength"
                    className="form-control"
                    placeholder="Write here length in mm"
                    onChange={(e) => handleServiceChange(e, index)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="form-group">
                <div className="form-input">
                  <label for="width">Width(mm):</label>
                  <input
                    type="number"
                    name="carWidth"
                    className="form-control"
                    placeholder="Write here width in mm"
                    onChange={(e) => handleServiceChange(e, index)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="form-group">
                <div className="form-input">
                  <label for="height">Height(mm):</label>
                  <input
                    type="number"
                    name="carHeight"
                    className="form-control"
                    placeholder="Write here height in mm"
                    onChange={(e) => handleServiceChange(e, index)}
                  />
                </div>
              </div>
            </div>
          </div>

          {serviceList.length !== 1 && (
            <button
              type="button"
              onClick={() => handleServiceRemove(index)}
              className="remove-btn"
            >
              <span>X</span>
            </button>
          )}

<hr></hr>
          <hr></hr>
        </div>
      ))}
      {/* All Records:
      {serviceList &&
        serviceList.map((singleService, index) => (
          <ul key={index}>
            <li>
              <hr></hr>
              {index + 1}
              <br></br>
              {singleService.modelId && "Make Id:" + singleService.makeId}{" "}
              <br></br>
              {singleService.modelId &&
                "Model Id:" + singleService.modelId}{" "}
              <br></br>
              {singleService.yearId && "Year Id:" + singleService.yearId}{" "}
              <br></br>
              {singleService.drivableStatus &&
                "Drivable Status:" + singleService.drivableStatus}{" "}
              <br></br>
              {singleService.modificationStatus &&
                "Modification Status:" + singleService.modificationStatus}{" "}
              <br></br>
              {singleService.goodsStatus &&
                "Goods Status:" + singleService.goodsStatus}{" "}
              <br></br>
              {singleService.modificationTypeId &&
                "Modification Type:" + singleService.modificationTypeId}{" "}
              <br></br>
              {singleService.modificationDetail &&
                "Modification Detail:" + singleService.modificationDetail}{" "}
              <br></br>
              {singleService.carGroundClearance &&
                "Ground Clearance:" + singleService.carGroundClearance}{" "}
              <br></br>
              {singleService.carLength &&
                "-Car Length:" + singleService.carLength}{" "}
              <br></br>
              {singleService.carWidth &&
                "-Car Width:" + singleService.carWidth}{" "}
              <br></br>
              {singleService.carHeight &&
                "-Car Height:" + singleService.carHeight}{" "}
              <br></br>
            </li>
          </ul>
        ))} */}
    </>
  );
}
