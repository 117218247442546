import React, { useEffect } from "react";
import $ from "jquery";
import "jquery-ui-bundle/jquery-ui";
import "jquery-ui-bundle/jquery-ui.css";
import configData from "./../../config.json";



export default function SearchSuburbAutocomplete({ inputProps, onSourceSelect }) {
  
  useEffect(() => {
    try {
      var _userFocusOutData = "";
      $("#" + inputProps.id).autocomplete({
        delay: 0,
        autoFocus: true,
        autoFill: true,
        scrollHeight: 220,
        minLength: 2,
        source: function(request, response) {
          $.ajax({
            url: configData.SERVER_URL +`search_suburb/${request.term}`,
            dataType: "json",
            data: {
              q: request.term
            },
            success: function(data) {
              _userFocusOutData = data;
              response(
                $.map(data, function(item) {
                  return {
                    value: item.full_name
                  };
                })
              );
            }
          });
        },
        select: function(event, ui) {
          onSourceSelect(ui.item);
        },
        change: function(event, ui) {
          if (!ui.item) {
            var _topElement = _userFocusOutData.city_list[0];
            onSourceSelect({
              value: _topElement.city_name
            });
            $(this).val(_topElement.full_name);
          }
        },
        open: function() {
          $(this)
            .removeClass("ui-corner-all")
            .addClass("ui-corner-top");
        },
        close: function() {
          $(this)
            .removeClass("ui-corner-top")
            .addClass("ui-corner-all");
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  


  return (
    <div>
      <input
        className="form-control"
        type="text"
        id={inputProps.id}
        value={inputProps.value}
        onChange={(e)=>onSourceSelect({label:e.target.value,code:'',name:e.target.value,value:e.target.value})}
       />
    </div>
  );
}